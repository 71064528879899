.menu-drawer-content {
  background-color: #fffafa;
  color: #fff;
  width: 25vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.menu-drawer-content::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.drawerText {
  font-family: "Permanent Marker", cursive;
  font-family: "Staatliches", cursive;
  color: #e35858;
  font-size: 32px;
  text-align: center;
}

.social-media {
  display: flex;
  justify-content: space-between;
  margin: 4em;
}

.clean-logo,
.clean-logo:focus,
.clean-logo:active {
  color: #e35858;
}

.clean-logo:hover {
  color: #ffb2b2;
}

.text-container {
  width: 90%;
  margin-left: 10%;
  margin-right: 10%;
  display: block;
  margin-top: 20%;
  font-family: "Permanent Marker", cursive;
  font-family: "Staatliches", cursive;
  text-align: center;
}
.logo {
  height: 100%;
  position: absolute;
}

.menu-drawer-header {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 4rem;
}

.menu-drawer-category {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.menu-drawer-category h2 {
  margin-top: 1rem;
  margin-bottom: 0;
}

.menu-drawer-food {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 1rem;
  white-space: pre-line;
}

.menu-drawer-food p {
  margin: 0;
}

.menu-drawer-hr {
  width: 75%;
  border: 1px solid #fff;
  background-color: #fffafa;
}

.close-menu {
  position: fixed;
  right: 10px;
  top: 10px;
  color: #b5bdc9;
  visibility: hidden;
}

.close-about {
  position: fixed;
  left: 10px;
  top: 10px;
  color: #b5bdc9;
  visibility: hidden;
}
@media only screen and (max-width: 700px) {
  .menu-drawer-content {
    background-color: #fffafa;
    color: #fff;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .menu-drawer-content::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .menu-drawer-food {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1rem;
    white-space: pre-line;
  }

  .menu-drawer-category {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .close-menu {
    position: absolute;
    zindex: 100;
    right: 10px;
    top: 10px;
    color: #b5bdc9;
    visibility: visible;
  }
  .close-about {
    position: fixed;
    left: 10px;
    top: 10px;
    color: #b5bdc9;
    visibility: visible;
  }
  .text-container {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    margin-top: 30%;
    margin-bottom: 20%;
  }
}
